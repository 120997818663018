import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { baseUrl } from "../Utils/api";
import axios from "axios";

const initialState = {
  allQoutes: [],
  loading: false,
  qouteDetails: {},
  downloadLoading:false
};
const token = localStorage.getItem("auth-token");

export const fetchAllQoutes = createAsyncThunk("fetchAllQoutes", async () => {
  try {
    const response = await axios({
      method: "GET",
      url: `${baseUrl}/quotation?size=10000`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error) {
    console.log(error);
  }
});

export const viewSingleQoutes = createAsyncThunk(
  "viewSingleQoutes",
  async (id) => {
    try {
      const response = await axios({
        method: "GET",
        url: `${baseUrl}/quotation/${id}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  }
);

export const approveQuote = createAsyncThunk(
  "approveQuote",
  async (quotationData, { dispatch }) => {
    const data = { ...quotationData };
    delete data.id;
    delete data.created_at;
    delete data.updated_at;
    delete data.quotation_status;
    data.discount = 0;
    try {
      const response = await axios({
        method: "PUT",
        url: `${baseUrl}/quotation/${quotationData?.id}/approve`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: JSON.stringify(data),
      });
      dispatch(fetchAllQoutes());
      return response;
    } catch (error) {
      console.log(error);
    }
  }
);

export const acceptQuote = createAsyncThunk(
  "acceptQuote",
  async ({ quotationData, api }, { dispatch }) => {
    const data = { ...quotationData };
    delete data.id;
    delete data.created_at;
    delete data.updated_at;
    delete data.quotation_status;
    data.discount = 0;
    try {
      const response = await axios({
        method: "PUT",
        url: `${baseUrl}/quotation/${quotationData?.id}/accept`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: JSON.stringify(data),
      });
      api.success({
        message: "Quotation Accepted",
        style: {
          width: 300,
        },
      });
      dispatch(fetchAllQoutes());
      return response;
    } catch (error) {
      console.log(error);
    }
  }
);

export const deleteQuote = createAsyncThunk(
  "deleteQuote",
  async ({ quotationData, api }, { dispatch }) => {
    const data = { ...quotationData };
    delete data.id;
    delete data.created_at;
    delete data.updated_at;
    delete data.quotation_status;
    data.discount = 0;
    try {
      const response = await axios({
        method: "PUT",
        url: `${baseUrl}/quotation/${quotationData?.id}/delete`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: JSON.stringify(data),
      });
      api.success({
        message: "Quotation Rejected",
        style: {
          width: 300,
        },
      });
      dispatch(fetchAllQoutes());
      return response;
    } catch (error) {
      api.error({
        message: "Something went wrong!",
        description: error?.response?.data?.[0]?.message,
        style: {
          width: 300,
        },
      });
      console.log(error?.response);
    }
  }
);

export const downloadQuote = createAsyncThunk(
  "downloadQuote",
  async (id, { dispatch }) => {
    try {
      const response = await axios({
        method: "GET",
        url: `
        https://quotation-report.edencaremedical.com/api/documentTemplates/${id}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/pdf",
        },
      });
console.log(response)
      return response;
    } catch (error) {
      console.log(error?.response);
    }
  }
);

const quotesSlice = createSlice({
  name: "quotesSlice",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchAllQoutes.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllQoutes.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.data) {
        state.allQoutes = action.payload.data;
      } else {
        state.allQoutes = [];
      }
    });
    builder.addCase(fetchAllQoutes.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(viewSingleQoutes.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(viewSingleQoutes.fulfilled, (state, action) => {
      state.loading = false;
      state.qouteDetails = action.payload?.data;
    });
    builder.addCase(viewSingleQoutes.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(approveQuote.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(approveQuote.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(approveQuote.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(acceptQuote.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(acceptQuote.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(acceptQuote.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(deleteQuote.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteQuote.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(deleteQuote.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(downloadQuote.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(downloadQuote.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(downloadQuote.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export default quotesSlice.reducer;
